/* Make all text white */
.apexcharts-xaxis-label,
.apexcharts-yaxis-label,
.apexcharts-title-text,
.apexcharts-yaxis-title-text,
.apexcharts-xaxis-title-text {
  fill: #fff; /* Set text color to white */
}
  

/* Make legend text white */
.apexcharts-legend-text {
  color: #fff; /* Set legend text color to white */
  fill:#fff;
}


.progress-bar {
  width: 100%; /* Set the width of the bars */
  height: 20px; /* Set the height of the bars */
  background: #f0f0f0; /* Background color of the bars */
  display: inline-block;
  margin: 0 10px; /* Adjust spacing between bars */
  margin-top:10px;
}

.fill1 {
  width: 100%;
  background: #4CAF50; /* Color of the filled portion of the bars */
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; /* Animation duration */
}

.fill2 {
  width: 100%;
  background: red; /* Color of the filled portion of the bars */
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; /* Animation duration */
}

.fill3 {
  width: 100%;
  background: #ef843c; /* Color of the filled portion of the bars */
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; /* Animation duration */
}

.fill4 {
  width: 100%;
  background: #93A3B8; /* Color of the filled portion of the bars */
  text-align: center;
  color: black;
  font-weight: bold;
  transition: width 2s; /* Animation duration */
}







